import React from 'react'
import { useAuth0 } from '@auth0/auth0-react'

const LoginButton = () => {
  const { loginWithRedirect } = useAuth0()

  return (
    <span className="text-warning" onClick={() => loginWithRedirect()}>
      ©
    </span>
  )
}

export default LoginButton
