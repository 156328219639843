import React from 'react'
import { ImgComparisonSlider } from '../../components'
import './_rates.scss'

const Rates = () => {
  return (
    <div className="rates mt-5">
      <div className="container">
        <h1 className="text-start my-5 pt-5 pb-2 border-bottom">Rates</h1>
        <p>
          Each type of photography has a different rates model. I try to keep my
          rates flexible. This page is just a range for various categories
          please{' '}
          <a href="./contact" alt="contact" className="text-warning">
            contact
          </a>{' '}
          me for a free estimate.
        </p>
        <div className="text-center m-5">
          <div className="row mb-3">
            <div className="col-lg-3 mb-4">
              <div className="card bg-transparent border-0">
                <img
                  src="https://onsmallphoto.s3.amazonaws.com/wedding-tori-1.jpg"
                  className="card-img-top"
                  alt="..."
                />
                <div className="card-body ">
                  <h5 className="card-header cedarville">
                    Wedding Photography
                  </h5>
                  <p className="card-title">
                    <span className="text-warning">$</span>1,500 –{' '}
                    <span className="text-warning">$</span>3,500
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-3 mb-4">
              <div className="card bg-transparent border-0">
                <img
                  src="https://onsmallphoto.s3.amazonaws.com/wedding-nelson-rings.jpg"
                  className="card-img-top"
                  alt="..."
                />
                <div className="card-body">
                  <h5 className="card-header cedarville">
                    Engagement Photography
                  </h5>
                  <p className="card-title">
                    <span className="text-warning">$</span>150 –{' '}
                    <span className="text-warning">$</span>500
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-3 mb-4">
              <div className="card bg-transparent border-0">
                <img
                  src="https://onsmallphoto.s3.amazonaws.com/event-olg-1.jpg"
                  className="card-img-top"
                  alt="..."
                />
                <div className="card-body">
                  <h5 className="card-header cedarville">Event Photography</h5>
                  <p className="card-title">
                    <span className="text-warning">$</span>500 –{' '}
                    <span className="text-warning">$</span>1,000
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-3 d-none">
              <div className="card bg-transparent border-0">
                <img
                  src="https://onsmallphoto.s3.amazonaws.com/1sp-logo.png"
                  className="card-img-top"
                  alt="..."
                />
                <div className="card-body">
                  <h5 className="card-header cedarville">Family Photo Shoot</h5>
                  <p className="card-title">
                    <span className="text-warning">$</span>145 –{' '}
                    <span className="text-warning">$</span>500
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-3 d-none">
              <div className="card bg-transparent border-0">
                <img
                  src="https://onsmallphoto.s3.amazonaws.com/1sp-logo.png"
                  className="card-img-top"
                  alt="..."
                />
                <div className="card-body ">
                  <h5 className="card-header cedarville">Senior Pictures</h5>
                  <p className="card-title">
                    <span className="text-warning">$</span>125 –{' '}
                    <span className="text-warning">$</span>350
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-3 mb-4">
              <div className="card bg-transparent border-0">
                <img
                  src="https://onsmallphoto.s3.amazonaws.com/portrait-cassie-1.jpg"
                  className="card-img-top"
                  alt="..."
                />
                <div className="card-body">
                  <h5 className="card-header cedarville">
                    Portrait Photography
                  </h5>
                  <p className="card-title">
                    <span className="text-warning">$</span>150 –{' '}
                    <span className="text-warning">$</span>370+
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-3 mb-4">
              <div className="card bg-transparent border-0">
                <img
                  src="https://onsmallphoto.s3.amazonaws.com/portrait-owen-2.jpg"
                  className="card-img-top"
                  alt="..."
                />
                <div className="card-body">
                  <h5 className="card-header cedarville">
                    Birth, Baby and Newborn Photography
                  </h5>
                  <p className="card-title">
                    <span className="text-warning">$</span>320 –{' '}
                    <span className="text-warning">$</span>650
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-3 mb-4">
              <div className="card bg-transparent border-0">
                <img
                  src="https://onsmallphoto.s3.amazonaws.com/1sp-logo.png"
                  className="card-img-top"
                  alt="..."
                />
                <div className="card-body">
                  <h5 className="card-header cedarville">
                    Maternity Photography
                  </h5>
                  <p className="card-title">
                    <span className="text-warning">$</span>350 –{' '}
                    <span className="text-warning">$</span>1,500+
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-3 mb-4">
              <div className="card bg-transparent border-0">
                <img
                  src="https://onsmallphoto.s3.amazonaws.com/nature-snow-dogs.jpg"
                  className="card-img-top"
                  alt="..."
                />
                <div className="card-body ">
                  <h5 className="card-header cedarville">Pet Photography</h5>
                  <p className="card-title">
                    <span className="text-warning">$</span>150 –{' '}
                    <span className="text-warning">$</span>300
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-3 mb-4">
              <div className="card bg-transparent border-0">
                <img
                  src="https://onsmallphoto.s3.amazonaws.com/1sp-logo.png"
                  className="card-img-top"
                  alt="..."
                />
                <div className="card-body">
                  <h5 className="card-header cedarville">
                    Real Estate Photography
                  </h5>
                  <p className="card-title">
                    <span className="text-warning">$</span>150 –{' '}
                    <span className="text-warning">$</span>1,500
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-3 mb-4">
              <div className="card bg-transparent border-0">
                <img
                  src="https://onsmallphoto.s3.amazonaws.com/product-jay-1.jpg"
                  className="card-img-top"
                  alt="..."
                />
                <div className="card-body">
                  <h5 className="card-header cedarville">
                    Product Photography
                  </h5>
                  <p className="card-title">
                    <span className="text-warning">$</span>35 –{' '}
                    <span className="text-warning">$</span>170 per image
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-3 mb-4">
              <div className="card bg-transparent border-0">
                <img
                  src="https://onsmallphoto.s3.amazonaws.com/landscape-dc.jpg"
                  className="card-img-top"
                  alt="..."
                />
                <div className="card-body">
                  <h5 className="card-header cedarville">
                    Commercial Photography
                  </h5>
                  <p className="card-title">
                    <span className="text-warning">$</span>250+ per image
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-3 d-none">
              <div className="card bg-transparent border-0">
                <img
                  src="https://onsmallphoto.s3.amazonaws.com/1sp-logo.png"
                  className="card-img-top"
                  alt="..."
                />
                <div className="card-body ">
                  <h5 className="card-header cedarville">Prom Photography</h5>
                  <p className="card-title">
                    <span className="text-warning">$</span>75 –{' '}
                    <span className="text-warning">$</span>125
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-3 d-none">
              <div className="card bg-transparent border-0">
                <img
                  src="https://onsmallphoto.s3.amazonaws.com/1sp-logo.png"
                  className="card-img-top"
                  alt="..."
                />
                <div className="card-body">
                  <h5 className="card-header cedarville">
                    Graduation Photoshoot
                  </h5>
                  <p className="card-title">
                    <span className="text-warning">$</span>100 –{' '}
                    <span className="text-warning">$</span>300
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-3 mb-4">
              <div className="card bg-transparent border-0">
                <img
                  src="https://onsmallphoto.s3.amazonaws.com/portrait-sirina-photobooth.png"
                  className="card-img-top"
                  alt="..."
                />
                <div className="card-body">
                  <h5 className="card-header cedarville">Headshots</h5>
                  <p className="card-title">
                    <span className="text-warning">$</span>75 –{' '}
                    <span className="text-warning">$</span>325
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-3 mb-4">
              <div className="card bg-transparent border-0">
                <img
                  src="https://onsmallphoto.s3.amazonaws.com/1sp-logo.png"
                  className="card-img-top"
                  alt="..."
                />
                <div className="card-body">
                  <h5 className="card-header cedarville">Food Photography</h5>
                  <p className="card-title">
                    <span className="text-warning">$</span>150 –{' '}
                    <span className="text-warning">$</span>500+
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-3 mb-4">
              <div className="card bg-transparent border-0">
                <img
                  src="https://onsmallphoto.s3.amazonaws.com/1sp-logo.png"
                  className="card-img-top"
                  alt="..."
                />
                <div className="card-body ">
                  <h5 className="card-header cedarville">
                    Lifestyle Photography
                  </h5>
                  <p className="card-title">
                    <span className="text-warning">$</span>150 –{' '}
                    <span className="text-warning">$</span>370
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-3 mb-4">
              <div className="card bg-transparent border-0">
                <img
                  src="https://onsmallphoto.s3.amazonaws.com/portrait-nova.jpg"
                  className="card-img-top"
                  alt="..."
                />
                <div className="card-body">
                  <h5 className="card-header cedarville">Band Photography</h5>
                  <p className="card-title">
                    <span className="text-warning">$</span>300 –{' '}
                    <span className="text-warning">$</span>1000
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-3 mb-4">
              <div className="card bg-transparent border-0">
                <img
                  src="https://onsmallphoto.s3.amazonaws.com/nature-ducks.jpg"
                  className="card-img-top"
                  alt="..."
                />
                <div className="card-body">
                  <h5 className="card-header cedarville">Nature Photography</h5>
                  <p className="card-title">
                    <span className="text-warning">$</span>200 –{' '}
                    <span className="text-warning">$</span>500
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-3 mb-4">
              <div className="card bg-transparent border-0">
                <img
                  src="https://onsmallphoto.s3.amazonaws.com/event-pb-2.jpg"
                  className="card-img-top"
                  alt="..."
                />
                <div className="card-body">
                  <h5 className="card-header cedarville">Sports Photography</h5>
                  <p className="card-title">
                    <span className="text-warning">$</span>150 –{' '}
                    <span className="text-warning">$</span>300
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-3 mb-4">
              <div className="card bg-transparent border-0">
                <img
                  src="https://onsmallphoto.s3.amazonaws.com/1sp-logo.png"
                  className="card-img-top"
                  alt="..."
                />
                <div className="card-body ">
                  <h5 className="card-header cedarville">
                    Fashion Photography
                  </h5>
                  <p className="card-title">
                    <span className="text-warning">$</span>400 –{' '}
                    <span className="text-warning">$</span>1,000 per session
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-3 mb-4">
              <div className="card bg-transparent border-0">
                <img
                  src="https://onsmallphoto.s3.amazonaws.com/1sp-logo.png"
                  className="card-img-top"
                  alt="..."
                />
                <div className="card-body">
                  <h5 className="card-header cedarville">
                    Website Photography
                  </h5>
                  <p className="card-title">
                    <span className="text-warning">$</span>35 –{' '}
                    <span className="text-warning">$</span>150 per image
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-3 mb-4">
              <div className="card bg-transparent border-0">
                <img
                  src="https://onsmallphoto.s3.amazonaws.com/cosplay-jay-link-4.jpg"
                  className="card-img-top"
                  alt="..."
                />
                <div className="card-body">
                  <h5 className="card-header cedarville">
                    Cosplay Photography
                  </h5>
                  <p className="card-title">
                    <span className="text-warning">$</span>150 –{' '}
                    <span className="text-warning">$</span>370+
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-3 mb-4">
              <div className="card bg-transparent border-0">
                <img
                  src="https://onsmallphoto.s3.amazonaws.com/boudoir-misha-1.jpg"
                  className="card-img-top"
                  alt="..."
                />
                <div className="card-body">
                  <h5 className="card-header cedarville">
                    Boudoir Photography
                  </h5>
                  <p className="card-title">
                    <span className="text-warning">$</span>150 –{' '}
                    <span className="text-warning">$</span>1,200 per session
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Photowizardry */}
        <h1 className="text-start my-5 pt-5 pb-2 border-bottom">
          Photo Wizardry
        </h1>
        <div className="row text-start mb-5">
          <div className="col-md-12 text-center">
            <div className="fs-5 fw-bold mb-3">
              How Is Photo Retouching Different From Photo Editing?
            </div>
            <p>
              Photo editing is relatively straightforward. When you edit photos,
              you’re changing very subtle elements in your images ranging from
              basic light adjustments to minimal color correction. The work that
              needs to be done per image takes up very little time, so most
              editing can be done quickly on apps or programs. On the other
              hand, retouching entails more than simply editing images to make
              them look good. Photo retouching is the art of enhancing images
              using every tool of the trade. From smoothing skin, whitening
              teeth, and adjusting lighting to compositing a background, model,
              or object – anything is possible with retouching.
            </p>
          </div>
        </div>
        <div className="row text-start mb-5 text-center">
          <div className="col-md-3">
            <ul className="list-group bg-transparent">
              <li className="list-group-item mb-2 mt-5 py-2 bg-transparent text-white text-uppercase border-0 border-bottom">
                <b>Basic Retouching</b>
                <p>
                  <span className="text-warning">$</span>5 –{' '}
                  <span className="text-warning">$</span>10+ per imgage
                </p>
              </li>
              <li className="list-group-item bg-transparent text-white border-0">
                Color correction
              </li>
              <li className="list-group-item bg-transparent text-white border-0">
                Lighting adjustments
              </li>
              <li className="list-group-item bg-transparent text-white border-0">
                Teeth whitening
              </li>
              <li className="list-group-item bg-transparent text-white border-0">
                Removing minor blemishes
              </li>
              <li className="list-group-item bg-transparent text-white border-0">
                Removing stray hairs
              </li>
            </ul>
            <ul className="list-group bg-transparent">
              <li className="list-group-item mb-2 mt-5 py-2 bg-transparent text-white text-uppercase border-0 border-bottom">
                <b>Advanced Retouching</b>
                <p>
                  <span className="text-warning">$</span>15 –{' '}
                  <span className="text-warning">$</span>25+ per imgage
                </p>
              </li>
              <li className="list-group-item bg-transparent text-white border-0">
                Basic Retouching
              </li>
              <li className="list-group-item bg-transparent text-white border-0">
                Blemish removal
              </li>
              <li className="list-group-item bg-transparent text-white border-0">
                Skin smoothing
              </li>
              <li className="list-group-item bg-transparent text-white border-0">
                Removing background objects
              </li>
            </ul>
            <ul className="list-group bg-transparent">
              <li className="list-group-item mb-2 mt-5 py-2 bg-transparent text-white text-uppercase border-0 border-bottom">
                <b>Extensive Retouching</b>
                <p>
                  <span className="text-warning">$</span>30 –{' '}
                  <span className="text-warning">$</span>50+ per imgage
                </p>
              </li>
              <li className="list-group-item bg-transparent text-white border-0">
                Advanced Retouching
              </li>
              <li className="list-group-item bg-transparent text-white border-0">
                Background compositing
              </li>
              <li className="list-group-item bg-transparent text-white border-0">
                Skin-end skin correction
              </li>
              <li className="list-group-item bg-transparent text-white border-0">
                Comprehensive editing
              </li>
            </ul>
          </div>
          <div className="col-md-6">
            <ImgComparisonSlider
              imgBefore={`https://onsmallphoto.s3.amazonaws.com/model-brittany-3-before.png`}
              imgAfter={`https://onsmallphoto.s3.amazonaws.com/model-brittany-3.jpg`}
            />
          </div>
          <div className="col-md-3">
            <div className="fs-5 fw-bold mb-3">
              What Factors Will Affect The Retouching Rate?
            </div>
            <ul className="list-group bg-transparent">
              <li className="list-group-item bg-transparent text-white border-0">
                Usage per photo
              </li>
              <li className="list-group-item bg-transparent text-white border-0">
                Number of revision requests
              </li>
              <li className="list-group-item bg-transparent text-white border-0">
                Time-sensitive work
              </li>
              <li className="list-group-item bg-transparent text-white border-0">
                Stylizing retouches
              </li>
              <li className="list-group-item bg-transparent text-white border-0">
                Restoring damaged photos
              </li>
              <li className="list-group-item bg-transparent text-white border-0">
                Printing high quality images
              </li>
              <li className="list-group-item bg-transparent text-white border-0">
                Delivering prints to your home
              </li>
              <li className="list-group-item bg-transparent text-white border-0">
                Rush or last minute photo editing
              </li>
            </ul>
          </div>
        </div>
        {/* TFP */}
        <h1 className="text-start my-5 pt-5 pb-2 border-bottom">
          <span className="text-warning">T</span>ime-
          <span className="text-warning">F</span>or-
          <span className="text-warning">P</span>rints
        </h1>
        <div className="row text-center mb-5">
          <div className="col-md-1" />
          <div className="col-md-4">
            <div className="fs-5 fw-bold mb-3">What is TFP Photography?</div>
            <p>
              TFP stands for Time For Prints. A photographer and a model(s)
              collaborates on a photography project. Everyone works for free,
              but they all receive the TFP shots to use in their portfolios.
              Hair stylists, makeup artists, and photography assistants can also
              take part in TFP photoshoots to help build their portfolios.
            </p>
          </div>
          <div className="col-md-2" />
          <div className="col-md-4">
            <div className="fs-5 fw-bold mb-3">
              What Goes Into a TFP Agreement?
            </div>
            <p>
              Even though no money is exchanged during a TFP shoot and TFP
              photography is intended to be used for portfolios only. Contracts
              and model releases exist for a reason. It’s an easy way to make
              sure that everyone is on the same page as how they can use their
              images. I keep it pretty simple. The images are free to use as
              long as they're not edited, you don't apply filters to them when
              using them on social media and you don't use them for profit.
            </p>
          </div>
          <div className="col-md-1" />
        </div>
      </div>
    </div>
  )
}

export default Rates
